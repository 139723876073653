var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-10 my-10",attrs:{"max-width":"1600"}},[_c('v-card-title',[_c('h3',[_vm._v("Rotations")]),_c('div',{staticClass:"ml-auto"},[(_vm.selectedRotations.length)?_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.completeRotations}},[_vm._v("Create Invoices ")]):_vm._e(),(_vm.selectedRotations.length)?_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary"},on:{"click":_vm.setValidationStatus}},[_vm._v("Request Validation ")]):_vm._e()],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"selectable-key":"id","show-select":"","headers":_vm.headers,"flat":"","items":_vm.rotations,"items-per-page":10},scopedSlots:_vm._u([{key:"item.caretaker",fn:function(ref){
var item = ref.item;
return [(!item.assignee)?_c('v-chip',{staticClass:"pointer",attrs:{"small":""}},[_vm._v("Unassigned")]):_c('div',{staticClass:"user-details"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(item.assignee.name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.assignee.email))])])]}},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pointer",attrs:{"label":"","color":_vm.getConfirmationColor(item.recruiter_confirmed)}},[_vm._v(" "+_vm._s(_vm.getConfirmationText(item.recruiter_confirmed))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pointer",attrs:{"label":"","color":_vm.getRotationStatusColor(item)}},[_vm._v(_vm._s(item.status))]),(item.has_problem)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#f49636"}},[_vm._v("mdi-alert-circle-outline")]):_vm._e()]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.start_date,"DD MMM YY")))])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.end_date,"DD MMM YY")))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"pointer"},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.last_name))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" Days ")]}},{key:"item.assignee_fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignee_fee)+" Eur ")]}},{key:"item.invoice_estimation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice_estimation)+" Eur ")]}},{key:"item.assignee_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignee_tax)+"% ")]}},{key:"item.invoice_estimation_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice_estimation_tax)+" Eur ")]}},{key:"item.case_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"white","color":_vm.getCaseColor(item)}},[_vm._v(" № "+_vm._s(item.contract_job_id)+"-"+_vm._s(item.rotation_number)+" ")])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openRotationControls(item)}}},[_vm._v("mdi-pencil-outline")])]}}]),model:{value:(_vm.selectedRotations),callback:function ($$v) {_vm.selectedRotations=$$v},expression:"selectedRotations"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }